// ImageWithOverlay.js
import React from 'react';
import './ImageWithOverlay.css'; // Import CSS for styling
import homewallpaper from '../assets/homeImg06.jpg';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
    margin: '5px',
    backgroundColor: '#ff6b00',
    color: '#fff'
});
const ImageWithOverlay = () => {
  return (
    <div className="image-container">
      <img
        src={homewallpaper}
        alt="Your Description"
        className="background-image"
      />
      <div className="overlay-text">
        <h1>One stop
            <br></br>Full Stack and 
        <br></br> Cyber Solutions</h1>
        <p className="shortDesc">Develop and maintain top-notch, secure applications and teams.
          <br></br>Maintain your organization's safety against actual threats from the real world.
        </p>
        
        <StyledButton variant="contained" >For Individuals</StyledButton>
        <StyledButton variant="contained">For Business</StyledButton>
        <StyledButton variant="contained">For Students</StyledButton>
      </div>
    </div>
  );
};

export default ImageWithOverlay;
