import React from 'react';
import { styled } from '@mui/system';
import SecurityIcon from '@mui/icons-material/Security';
import GppGoodIcon from '@mui/icons-material/GppGood';
import ShieldIcon from '@mui/icons-material/Shield';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  gap: '2rem',
  padding: '40px 20px',
  flexWrap: 'wrap',
  maxWidth: '1200px',
  margin: '0 auto',
});

const FeatureCard = styled('div')({
  background: '#151b26',
  borderRadius: '12px',
  padding: '24px',
  width: '300px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
});

const IconWrapper = styled('div')({
  color: '#e96101', // Orange color to match your theme
  fontSize: '48px',
  marginBottom: '16px',
  display: 'flex',
  justifyContent: 'center',
});

const Title = styled('h3')({
  fontSize: '1.25rem',
  fontWeight: 'bold',
  marginBottom: '12px',
  textAlign: 'center',
  color: '#fff',
});

const Description = styled('p')({
  fontSize: '1rem',
  color: '#8b95a1',
  lineHeight: '1.5',
  textAlign: 'center',
});

const SecurityFeatures= () => {
  const features = [
    {
      icon: <SecurityIcon sx={{ fontSize: 40 }} />,
      title: 'Comprehensive Cyber Protection',
      description: "Safeguard your digital infrastructure with our state-of-the-art security solutions. We leverage advanced technologies to shield your business from emerging cyber threats.",
    },
    {
      icon: <GppGoodIcon sx={{ fontSize: 40 }} />,
      title: 'Zero Trust Architecture Solutions',
      description: "Transform your security posture with our zero trust implementation services, custom-tailored to match your organization's unique requirements and goals.",
    },
    {
      icon: <ShieldIcon sx={{ fontSize: 40 }} />,
      title: 'Enterprise Asset Protection',
      description: 'Fortify your valuable digital resources with industry-leading security protocols and proactive threat prevention strategies.',
    },
  ];

  return (
    <Container>
      {features.map((feature, index) => (
        <FeatureCard key={index}>
          <IconWrapper>{feature.icon}</IconWrapper>
          <Title>{feature.title}</Title>
          <Description>{feature.description}</Description>
        </FeatureCard>
      ))}
    </Container>
  );
};

export default SecurityFeatures; 