// src/theme.ts
import { createTheme } from '@mui/material/styles';


const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');
const secondaryColor=getComputedStyle(document.documentElement).getPropertyValue('--secondaryColor');
const primaryFontColor=getComputedStyle(document.documentElement).getPropertyValue('--primaryFontColor');
const highlightColor=getComputedStyle(document.documentElement).getPropertyValue('--highlightColor');
console.log(primaryColor,secondaryColor);
const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor||'#1B262C', // Primary background
    },
    secondary: {
      main: secondaryColor||'#OF4C75', // Secondary background
    },
    text: {
      primary: primaryFontColor||'#BBE1FA', // Primary font color
    },
    action: {
      active:highlightColor||'#Ea553b', // Highlight color
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

export default theme;
