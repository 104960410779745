import React from 'react';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import TimelineIcon from '@mui/icons-material/Timeline';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import SupportIcon from '@mui/icons-material/Support';

const LifecycleSection = styled(motion.section)({
  padding: '60px 0',
  color: 'white',
  maxWidth: '1200px',
  margin: '0 auto',
});

const SectionHeading = styled(motion.div)({
  textAlign: 'center',
  marginBottom: '50px',
  '& h2': {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '16px',
    background: 'linear-gradient(45deg, #e96101, #ff7424)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  '& p': {
    color: '#8b95a1',
    fontSize: '1.1rem',
    maxWidth: '700px',
    margin: '0 auto',
  }
});

const TimelineContainer = styled(motion.div)({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  marginBottom: '60px',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    background: 'linear-gradient(90deg, #e96101, #ff7424)',
    top: '50%',
    zIndex: 0,
  }
});

const Phase = styled(motion.div)({
  background: '#151b26',
  padding: '24px',
  borderRadius: '12px',
  position: 'relative',
  zIndex: 1,
  width: '250px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
  '& h3': {
    fontSize: '1.2rem',
    marginBottom: '12px',
    color: '#e96101',
  },
  '& p': {
    color: '#8b95a1',
    fontSize: '0.9rem',
    lineHeight: '1.5',
  }
});

const WhyUsGrid = styled(motion.div)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '24px',
  marginTop: '40px',
});

const BenefitCard = styled(motion.div)({
  background: '#1e2a3e',
  padding: '24px',
  borderRadius: '12px',
  display: 'flex',
  gap: '16px',
  '& .icon': {
    color: '#e96101',
    fontSize: '2rem',
  },
  '& h4': {
    fontSize: '1.1rem',
    marginBottom: '8px',
    color: 'white',
  },
  '& p': {
    color: '#8b95a1',
    fontSize: '0.9rem',
    lineHeight: '1.5',
  }
});

const WebLifecycle = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.3 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <LifecycleSection
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <SectionHeading variants={itemVariants}>
        <h2>Web Application Lifecycle</h2>
        <p>Experience end-to-end excellence with our comprehensive web development approach</p>
      </SectionHeading>

      <TimelineContainer variants={itemVariants}>
        <Phase whileHover={{ y: -10 }}>
          <h3>Planning & Strategy</h3>
          <p>Deep analysis of requirements, market research, and strategic roadmap development</p>
        </Phase>
        <Phase whileHover={{ y: -10 }}>
          <h3>Design & Prototype</h3>
          <p>User-centric design with interactive prototypes and seamless UX workflows</p>
        </Phase>
        <Phase whileHover={{ y: -10 }}>
          <h3>Development</h3>
          <p>Agile development with modern tech stack and best coding practices</p>
        </Phase>
        <Phase whileHover={{ y: -10 }}>
          <h3>Deploy & Maintain</h3>
          <p>Continuous deployment, monitoring, and proactive maintenance</p>
        </Phase>
      </TimelineContainer>

      <WhyUsGrid variants={itemVariants}>
        <BenefitCard whileHover={{ scale: 1.02 }}>
          <SecurityIcon className="icon" />
          <div>
            <h4>Enterprise-Grade Security</h4>
            <p>Built-in security measures and compliance with industry standards</p>
          </div>
        </BenefitCard>
        <BenefitCard whileHover={{ scale: 1.02 }}>
          <SpeedIcon className="icon" />
          <div>
            <h4>Optimized Performance</h4>
            <p>Lightning-fast loading times and smooth user experience</p>
          </div>
        </BenefitCard>
        <BenefitCard whileHover={{ scale: 1.02 }}>
          <TimelineIcon className="icon" />
          <div>
            <h4>Scalable Architecture</h4>
            <p>Future-proof solutions that grow with your business</p>
          </div>
        </BenefitCard>
        <BenefitCard whileHover={{ scale: 1.02 }}>
          <SupportIcon className="icon" />
          <div>
            <h4>24/7 Expert Support</h4>
            <p>Dedicated team for continuous assistance and maintenance</p>
          </div>
        </BenefitCard>
      </WhyUsGrid>
    </LifecycleSection>
  );
};

export default WebLifecycle; 