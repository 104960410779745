// src/App.tsx
import React, { useEffect, useState } from 'react';
import { ThemeProvider, CssBaseline, styled } from '@mui/material';
import theme from './theme'; // Import your theme
import Header from './components/Header';
import HomePage from './components/HomePage';
interface Link {
  name: string;
  url: string;
  enable:boolean;
}

const App: React.FC = () => {
  const [links, setLinks] = useState<Link[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const AppContainer = styled('div')({
    backgroundColor: 'var(--primaryColor)', // Primary background color
    minHeight: '100vh', // Ensure it covers the full viewport height
  });

  useEffect(() => {
    // Import the JSON file dynamically
    import('./assets/headerLinks.json')
      .then((module) => {
        setLinks(module.default);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <AppContainer>
    <Header links={links} />
      <HomePage />
    </AppContainer>
  </ThemeProvider>
  );
};

export default App;
