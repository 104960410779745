import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface DataItem {
  name: string;
  value: number;
  icon: string; // Added icon property
}

interface BarChartProps {
  data: DataItem[];
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const maxValue = Math.max(...data.map(item => item.value));

  return (
    <div style={{ 
      padding: '20px 0',
      height: '100%',  // Fill the card height
      overflowY: 'auto', // Add scroll if needed
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'  // Consistent spacing between bars
    }}>
      {data.map((item) => {
        const opacity = 0.4 + (0.6 * item.value / maxValue);
        
        return (
          <div
            key={item.name}
            style={{
              width: `${(item.value / maxValue) * 100}%`,
              height: '35px', // Slightly reduced height
              backgroundColor: `rgba(255, 107, 0, ${opacity})`,
              borderRadius: '20px',
              padding: '0 20px',
              display: 'flex',
              alignItems: 'center',
              color: 'white',
              fontSize: '14px',
              fontWeight: 500,
              transition: 'all 0.3s ease',
              flexShrink: 0  // Prevent bars from shrinking
            }}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};

export default BarChart;
