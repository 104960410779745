import React, { useState } from 'react';
import styles from './ContactUs.module.css';
import contactUsImage from '../assets/contactUs.svg';
import emailjs from '@emailjs/browser';

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<{
    type: 'success' | 'error' | null;
    message: string;
  }>({ type: null, message: '' });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus({ type: null, message: '' });

    try {
      // Replace these with your actual EmailJS credentials
      const templateParams = {
        to_email: 'cybersecurity@tarachsolutions.com',
        from_name: formData.username,
        from_email: formData.email,
        phone: formData.phone,
        message: formData.message,
      };

      await emailjs.send(
        'YOUR_SERVICE_ID', // Replace with your EmailJS service ID
        'YOUR_TEMPLATE_ID', // Replace with your EmailJS template ID
        templateParams,
        'YOUR_PUBLIC_KEY' // Replace with your EmailJS public key
      );

      setSubmitStatus({
        type: 'success',
        message: 'Message sent successfully!'
      });
      setFormData({ username: '', email: '', phone: '', message: '' });
    } catch (error) {
      setSubmitStatus({
        type: 'error',
        message: 'Failed to send message. Please try again.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.cardsContainer}>
        {/* Info Card */}
        <div className={styles.card}>
          <div className={styles.infoContent}>
            <h2>Let's get in touch</h2>
            <p>Get in touch with us by filling out the form. We'll respond to your inquiry as soon as possible.</p>
            
            <div className={styles.contactInfo}>
              <div className={styles.contactItem}>
                <i className="fas fa-map-marker-alt" style={{ color: '#FF6B00' }} />
                <span>https://www.linkedin.com/in/kamalnath-reddy</span>
              </div>
              <div className={styles.contactItem}>
                <i className="fas fa-envelope" style={{ color: '#FF6B00' }} />
                <span>cybersecurity@tarachsolutions.com</span>
              </div>
              <div className={styles.contactItem}>
                <i className="fas fa-phone" style={{ color: '#FF6B00' }} />
                <span>+91-8919260020</span>
              </div>
            </div>

            <img 
              src={contactUsImage}
              alt="Contact illustration" 
              className={styles.illustration}
            />

            <div className={styles.socialLinks}>
              <h3>Connect with us:</h3>
              <div className={styles.socialIcons}>
                <a href="#facebook"><i className="fab fa-facebook" /></a>
                <a href="#twitter"><i className="fab fa-twitter" /></a>
                <a href="#linkedin"><i className="fab fa-linkedin" /></a>
                <a href="#instagram"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
        </div>

        {/* Form Card */}
        <div className={styles.card}>
          <div className={styles.formContent}>
            <h2>Send us a message</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <label htmlFor="username">Name</label>
                <input
                  id="username"
                  type="text"
                  name="username"
                  placeholder="Enter your name"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="phone">Phone</label>
                  <input
                    id="phone"
                    type="tel"
                    name="phone"
                    placeholder="Enter your phone number"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Type your message here..."
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={5}
                />
              </div>

              {submitStatus.message && (
                <div className={`${styles.statusMessage} ${styles[submitStatus.type || '']}`}>
                  {submitStatus.message}
                </div>
              )}

              <button 
                type="submit" 
                className={styles.submitButton}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;