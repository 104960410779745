// src/components/HomePage.tsx
import React from 'react';
import CarouselSlide from './CarouselSlide';
import { styled } from '@mui/system';
import image1 from '../assets/homeImg01.png';
import image2 from '../assets/homeImg02.png';
import image3 from '../assets/homeImg03.png';
import image4 from '../assets/homeImg04.png';
import homewallpaper from '../assets/homeImg05.jpg';
import './HomePage.css';
import ImageWithOverlay from './ImageWithOverlay';
import AnimatedSection from './AnimatedSection';
import LogoSlider from './LogoSlider';
import TwoCardLayout from './TwoCardLayout';
import { Tooltip } from '@mui/material';
import TechLogoGrid from './TechLogoGrid';
import Features from './Features';
import SecurityFeatures from './SecurityFeatures';
import WebLifecycle from './WebLifecycle';
import ContactUs from './ContactUs';
const HomePageContainer = styled('div')({
  width: '80%',
  margin: '0 auto',
  padding: '20px',
  backgroundColor: 'var(--primaryColor)', // Primary background color
});

const images = [image1,image2,image3,image4];

type TechLogo = {
  url: string;
  name: string;
};

const techLogos: TechLogo[] = [
  // Frontend
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg', name: 'HTML5' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg', name: 'CSS3' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg', name: 'JavaScript' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg', name: 'TypeScript' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg', name: 'React' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg', name: 'Redux' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg', name: 'Next.js' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg', name: 'Material-UI' },
  { 
    url: 'https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg', 
    name: 'Tailwind CSS' 
  },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg', name: 'Bootstrap' },
  
  // Backend
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg', name: 'Node.js' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg', name: 'Express.js' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg', name: 'Python' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg', name: 'Django' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original.svg', name: 'Flask' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg', name: 'Java' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original.svg', name: 'Spring' },
  
  // Database
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg', name: 'MongoDB' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg', name: 'PostgreSQL' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg', name: 'MySQL' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redis/redis-original.svg', name: 'Redis' },
  
  // DevOps & Tools
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg', name: 'Git' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg', name: 'Docker' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/kubernetes/kubernetes-plain.svg', name: 'Kubernetes' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-plain-wordmark.svg', name: 'AWS' },
  { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg', name: 'Firebase' },
];

const LogoContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '2rem',
  padding: '20px',
  maxWidth: '1200px',  // Added to ensure better layout control
  margin: '0 auto',    // Center the container
});

const TechLogo = styled('img')({
  width: '50px',
  height: '50px',
  objectFit: 'contain',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  '&[alt="Tailwind CSS"]': {  // Specific styling for Tailwind logo
    width: '60px',
    height: '60px',
  }
});

const StylishHeading = styled('h2')({
  textAlign: 'left',
  margin: '40px 0 20px',
  padding: '0 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-8px',
    left: '20px',
    width: '60%',
    height: '3px',
    background: 'linear-gradient(90deg, #e96101 0%, #ff8533 100%)',
    borderRadius: '2px',
  },
});

const SecurityHeading = styled(StylishHeading)({
  '&::before': {
    content: '"🔒"',
    marginRight: '10px',
    fontSize: '1.2rem',
  }
});

const FullstackHeading = styled(StylishHeading)({
  '&::before': {
    content: '"💻"',
    marginRight: '10px',
    fontSize: '1.2rem',
  }
});

const HomePage: React.FC = () => {
  return (
    <div>
      <ImageWithOverlay />
      <SecurityHeading>
        For Security
      </SecurityHeading>
      <LogoSlider/>
      <FullstackHeading>
        For Fullstack
      </FullstackHeading>
      <TechLogoGrid />
      <div>
        <TwoCardLayout />
      </div>
      <SecurityFeatures />
      <WebLifecycle />
      <Features />
      <ContactUs />
    </div>
  );
};

export default HomePage;
