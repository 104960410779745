import React from 'react';
import './LogoSlider.css';
import symantecLogo from '../assets/sec-symantec.png';
import mcafeeLogo from '../assets/sec-mcafee.png';
import solarwindsLogo from '../assets/sec-solarwinds.png';
import splunkLogo from '../assets/sec-splunk.png';
import paloAltoLogo from '../assets/sec-paloAlto.png';

const LogoSlider: React.FC = () => {
    const logos = [
        { url: symantecLogo, name: 'Broadcom (Symantec)', className: '' },
        { url: mcafeeLogo, name: 'McAfee', className: '' },
        { url: solarwindsLogo, name: 'SolarWinds', className: 'logo-larger' },
        { url: splunkLogo, name: 'Splunk', className: 'logo-larger' },
        { url: paloAltoLogo, name: 'Palo Alto Networks', className: 'logo-larger' }
    ];

    return (
        <div className="logo-section">
            <div className="logo-container">
                <div className="logo-track">
                    {[...logos, ...logos, ...logos].map((logo, index) => (
                        <div 
                            className="logo-slide" 
                            key={`logo-${index}`}
                            title={logo.name}
                        >
                            <img 
                                src={logo.url} 
                                alt={logo.name}
                                className={logo.className}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LogoSlider;