import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import { motion } from 'framer-motion';

const FeatureSection = styled(motion.section)({
  padding: '40px 0',
  color: 'white',
  maxWidth: '1200px',
  margin: '0 auto',
});

const MainHeading = styled(motion.h1)({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginBottom: '30px',
  maxWidth: '800px',
  color: 'white',
});

const ButtonGroup = styled(motion.div)({
  display: 'flex',
  gap: '20px',
  marginBottom: '40px',
});

const GetStartedButton = styled(motion.button)({
  backgroundColor: '#ff6b35',
  color: 'white',
  padding: '12px 24px',
  borderRadius: '8px',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '1rem',
  fontWeight: '500',
  '&:hover': {
    backgroundColor: '#ff8c63',
  },
});

const TeamsButton = styled(motion.button)({
  backgroundColor: 'transparent',
  color: '#666',
  padding: '12px 24px',
  borderRadius: '8px',
  border: '1px solid #e0e0e0',
  cursor: 'pointer',
  fontSize: '1rem',
  fontWeight: '500',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
});

const CardGrid = styled(motion.div)({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '24px',
  marginTop: '40px',
});

const FeatureCard = styled(motion.div)({
  backgroundColor: '#151b26',
  borderRadius: '12px',
  padding: '24px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '& h2': {
    fontSize: '1.5rem',
    marginBottom: '16px',
    color: '#fff',
  },
  '& p': {
    color: '#8b95a1',
    lineHeight: '1.5',
  },
});

const GuidedMode = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  backgroundColor: '#1c2331',
  padding: '12px',
  borderRadius: '8px',
  marginTop: '20px',
  '& .indicator': {
    width: '24px',
    height: '24px',
    backgroundColor: '#ff6b35',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .text': {
    '& h3': {
      margin: 0,
      fontSize: '1rem',
      color: '#fff',
    },
    '& p': {
      margin: 0,
      color: '#e96101',
      fontSize: '0.9rem',
    },
  },
});

const ScenarioGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '12px',
  marginTop: '20px',
});

const ScenarioItem = styled('div')({
  backgroundColor: '#1c2331',
  padding: '8px 12px',
  borderRadius: '4px',
  '& p': {
    fontSize: '0.9rem',
    color: '#8b95a1',
    margin: '0',
  },
});

// Add animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const Features = () => {
  return (
    <FeatureSection
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <MainHeading
        variants={itemVariants}
      >
        Master cybersecurity through interactive
        <br />
        challenges, from basics to expert levels.
      </MainHeading>
      
      <ButtonGroup
        as={motion.div}
        variants={itemVariants}
      >
        <GetStartedButton
          as={motion.button}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Get started
          <ArrowForwardIcon />
        </GetStartedButton>
        <TeamsButton
          as={motion.button}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          For teams
        </TeamsButton>
      </ButtonGroup>

      <CardGrid
        as={motion.div}
        variants={itemVariants}
      >
        <FeatureCard
          as={motion.div}
          whileHover={{ y: -5 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <h2>Structured Pathways</h2>
          <p>Step-by-step educational tracks designed to build your expertise in various security domains and career paths.</p>
          <GuidedMode>
            <div className="indicator">
              <CheckIcon sx={{ color: 'white', fontSize: '16px' }} />
            </div>
            <div className="text">
              <h3>Guided Mode</h3>
              <p>Enabled</p>
            </div>
          </GuidedMode>
        </FeatureCard>

        <FeatureCard
          as={motion.div}
          whileHover={{ y: -5 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <h2>Practical Challenges</h2>
          <p>Modern hands-on exercises featuring contemporary security threats and defense techniques — new content added weekly!</p>
          <ScenarioGrid>
            <ScenarioItem>
              <p>Web Application</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>Authentication</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>Reverse Engineering</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>Powershell</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>Arbitrary File Upload</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>Steganography</p>
            </ScenarioItem>
          </ScenarioGrid>
        </FeatureCard>

        <FeatureCard
          as={motion.div}
          whileHover={{ y: -5 }}
          transition={{ type: "spring", stiffness: 300 }}
        >
          <h2>Professional Credentials</h2>
          <p>Comprehensive preparation materials and practice tests to transform you into a certified security expert!</p>
          <ScenarioGrid>
            <ScenarioItem>
              <p>CompTIA Security+</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>CEH</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>CISSP</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>OSCP</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>AWS Security</p>
            </ScenarioItem>
            <ScenarioItem>
              <p>CCNA Security</p>
            </ScenarioItem>
          </ScenarioGrid>
        </FeatureCard>
      </CardGrid>
    </FeatureSection>
  );
};

export default Features; 