import React from 'react';
import { styled } from '@mui/system';
import { Tooltip } from '@mui/material';

const LogoContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    overflow: 'hidden',
    maskImage: 'linear-gradient(to right, transparent, black 15%, black 85%, transparent)',
    WebkitMaskImage: 'linear-gradient(to right, transparent, black 15%, black 85%, transparent)',
});

const LogoRow = styled('div')({
    display: 'flex',
    animation: 'scroll 30s linear infinite',
    width: 'fit-content',
    '&.reverse': {
        animation: 'scroll-reverse 30s linear infinite',
    },
});

const TechLogo = styled('img')({
    width: '50px',
    height: '50px',
    objectFit: 'contain',
    margin: '0 1rem',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    },
    '&[alt="Tailwind CSS"]': {
        width: '60px',
        height: '60px',
    }
});

const techLogos = [
    // Frontend
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg', name: 'HTML5' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg', name: 'CSS3' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg', name: 'JavaScript' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg', name: 'TypeScript' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg', name: 'React' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg', name: 'Redux' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg', name: 'Next.js' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg', name: 'Material-UI' },
    { url: 'https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg', name: 'Tailwind CSS' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg', name: 'Bootstrap' },
    
    // Backend
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg', name: 'Node.js' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg', name: 'Express.js' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg', name: 'Python' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/django/django-plain.svg', name: 'Django' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original.svg', name: 'Flask' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg', name: 'Java' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/spring/spring-original.svg', name: 'Spring' },
    
    // Database
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg', name: 'MongoDB' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg', name: 'PostgreSQL' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg', name: 'MySQL' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redis/redis-original.svg', name: 'Redis' },
    
    // DevOps & Tools
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg', name: 'Git' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg', name: 'Docker' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/kubernetes/kubernetes-plain.svg', name: 'Kubernetes' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-plain-wordmark.svg', name: 'AWS' },
    { url: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg', name: 'Firebase' },
];

// Split logos into two arrays
const firstRowLogos = techLogos.slice(0, Math.ceil(techLogos.length / 2));
const secondRowLogos = techLogos.slice(Math.ceil(techLogos.length / 2));

const TechLogoGrid: React.FC = () => {
    return (
        <LogoContainer>
            <style>
                {`
                    @keyframes scroll {
                        0% { transform: translateX(0); }
                        100% { transform: translateX(-50%); }
                    }
                    @keyframes scroll-reverse {
                        0% { transform: translateX(-50%); }
                        100% { transform: translateX(0); }
                    }
                `}
            </style>
            <LogoRow>
                {[...firstRowLogos, ...firstRowLogos].map((tech, index) => (
                    <Tooltip 
                        key={index} 
                        title={tech.name}
                        arrow
                        placement="top"
                    >
                        <TechLogo 
                            src={tech.url}
                            alt={tech.name}
                        />
                    </Tooltip>
                ))}
            </LogoRow>
            <LogoRow className="reverse">
                {[...secondRowLogos, ...secondRowLogos].map((tech, index) => (
                    <Tooltip 
                        key={index} 
                        title={tech.name}
                        arrow
                        placement="bottom"
                    >
                        <TechLogo 
                            src={tech.url}
                            alt={tech.name}
                        />
                    </Tooltip>
                ))}
            </LogoRow>
        </LogoContainer>
    );
};

export default TechLogoGrid; 