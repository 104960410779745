import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import card2Img from '../assets/pulse.png';
import CyberSecurityImage from './CyberSecurityImage';
import BarChart from './BarChart';
import cardbg1 from '../assets/cardbg1.png'

const TwoCardLayout: React.FC = () => {

  const data = [
    { name: 'Information Stealer - 246 Million', value: 246, icon: 'https://example.com/iconA.png' },
    { name: 'Trojan - 175 Million', value: 210, icon: 'https://example.com/iconA.png' },
    { name: 'Ransomware - 154 Million', value: 190, icon: 'https://example.com/iconA.png' },
    { name: 'RAT - 46 Million', value: 150, icon: 'https://example.com/iconA.png' },
    { name: 'APT - 40 Million', value: 148, icon: 'https://example.com/iconA.png' },
    { name: 'Botnet - 31 Million', value: 130, icon: 'https://example.com/iconA.png' },
    { name: 'Dropper - 20 Million', value: 120, icon: 'https://example.com/iconA.png' },
    { name: 'Backdoor - 14 Million', value: 114, icon: 'https://example.com/iconA.png' },
  ];

  return (
    <Grid container spacing={8} style={{ padding: '50px' }}>
      <Grid item xs={6}>
        <Card style={{
          background: `
            linear-gradient(
              rgba(0, 0, 0, 0.85), 
              rgba(0, 0, 0, 0.85)
            ),
            url(${cardbg1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '400px',
          backgroundColor: '#1A1A1A',
          position: 'relative'
        }}>
          <CardContent style={{ padding: '16px 24px 24px', margin:'-1rem' }}>
            <BarChart data={data} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card
          style={{
            background: '#1A1A1A', // Dark background
            color: 'white',
            height: '400px',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <CardContent style={{ 
            position: 'relative',
            zIndex: 2,
            height: '100%',
            padding: '32px'
          }}>
            <Typography 
              variant="h3" 
              component="div"
              sx={{
                fontSize: '2.5rem',
                fontWeight: 'bold',
                lineHeight: 1.2,
                marginBottom: '1rem'
              }}
            >
              Beat the odds.<br />
              Optimize your<br />
              performance.
            </Typography>
          </CardContent>
          {/* Orange wave SVG background */}
          <svg
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '60%',
              zIndex: 1
            }}
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
          >
            <path
              d="M0,70 C150,0 350,120 500,50 L500,150 L0,150 Z"
              fill="#FF6B00"
              opacity="0.6"
            />
            <path
              d="M0,90 C150,20 350,140 500,70 L500,150 L0,150 Z"
              fill="#FF6B00"
              opacity="0.4"
            />
          </svg>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TwoCardLayout;
