// src/components/Header.tsx
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Container, IconButton, Box } from '@mui/material';
import { styled } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person'; // Import profile icon
import './header.css';
import logo from '../assets/logo2.png';

interface Link {
  name: string;
  url: string;
  enable:boolean;
}

interface HeaderProps {
  links: Link[];
}

// Styled components
const StyledAppBar = styled(AppBar)({
  backgroundColor: 'var(--primaryColor)',
  color:'var(--primaryFontColor)',
  width:'100%' // Primary background
});

// const Title = styled(Typography)({
//   flexGrow: 1,
//   color: 'var(--primaryFontColor)', // Primary font color
// });

const StyledButton = styled(Button)({
  color: '#F5F5F5', // Primary font color
  margin: '0 10px',
  fontWeight:'500', // Spacing between buttons
  '&:hover': {
    color: '#FFA500', // Orange color on hover
  }
});

const ProfileIconButton = styled(IconButton)({
  color: 'var(--primaryFontColor)', // Primary font color
});

const Header: React.FC<HeaderProps> = ({ links }) => {
  return (
    <AppBar position="static">
      {/* <Container> */}
        <Toolbar>
          {/* <Title variant="h6" className="titleName">Tarach Solutions</Title> */}
          <img src={logo} className='logo'></img>
          <Box sx={{ flexGrow: 1, display: 'flex',color:'var(--primaryFontColor)', justifyContent: 'center' }}>
            {links.map((link) => (
              (link.enable)&&<StyledButton key={link.name} href={link.url} className="linkBtns">
                {link.name}
              </StyledButton>
            ))}
          </Box>
          {/* <ProfileIconButton>
            <PersonIcon />
          </ProfileIconButton> */}
        </Toolbar>
      {/* </Container> */}
    </AppBar>
  );
};

export default Header;
